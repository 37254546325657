<template>
  <div class="filter">
    <select class="select-number" v-model="selectOrder" @change="chgSelectOrder">
      <option v-for="(item,index) in listOrders" :key="index" :value="item">{{item}}</option>
    </select>
    <input class="write-number" v-model="orderNumber" />

    <a class="btn btn-def" @click="getOpperations"
       href="javascript:void(0)">Получить операции</a>
  </div>
  <div class="list-wrap">
    <div class="list-wrap--head">
      <div style="flex:1">Операция</div>
      <div style="flex:1">Время (мин)</div>
      <div style="flex:3">Элемент</div>
      <div style="flex:1">Date</div>
    </div>
    <div class="list-wrap--body" v-for="(item,index) in listOperations" :key="index">
      <div style="flex:1">{{item.operation_name}}</div>
      <div style="flex:1">{{item.normtime}}</div>
      <div style="flex:3">{{item.detail_name}}</div>
      <div style="flex:1">{{item.plan_date_begin}}</div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'OrderOperations',
  data() {
    return {
      loader: true,
      orderNumber: '',
      listOperations: [],
      selectOrder: '',
      listOrders: [],
      errors: [],
    };
  },
  created() {
    this.getOrders();
  },
  methods: {
    getOrders() {
      const str = '/admin/get-orders';
      axios.get(str).then((res) => {
        if (res.data.status) {
          this.listOrders = res.data.orders;
        }
      }).catch((e) => {
        this.errors.push(e);
      });
    },
    getOpperations() {
      const str = '/admin/get-operations?val=';

      axios.get(str + this.orderNumber).then((res) => {
        if (res.data.status) {
          this.listOperations = res.data.operations;
        }
      }).catch((e) => {
        this.errors.push(e);
      });
    },
    chgSelectOrder() {
      this.orderNumber = this.selectOrder;
      this.listOperations = [];
    },
  },
};
</script>

<style scoped>
  .list-wrap--head{display:flex;}
  .list-wrap--body{display:flex;}

  .list-wrap--head div{background: #caf4b2b3; padding: 6px 12px;}
  .list-wrap--body div{padding: 6px 12px;}

  .btn-def{background: #46b446; cursor:pointer; padding: 10px 18px;
    color: #fff;
    font-size: 14px;
    display:block;
    width: 170px;
    text-align: center;
    border-radius:4px;
    border:1px solid #dde7f3;
  }
  .btn-def:hover{background: #47d23f }

  .write-number{margin-right:10px;}
  .select-number{background: #fff; height: 46px; margin-right:10px;}
  .btn-def{display:inline-block;}

</style>
